import domReady from '@roots/sage/client/dom-ready';
import {countdowntimer} from './countdowntimer.js';
import {global} from './global.js';
import {$,jQuery} from 'jquery';
import {tronculator} from './tronculator.js';
import {faqs} from './faqs.js';
/**
 * Application entrypoint
 */
domReady(async () => {
  countdowntimer();
});

/**
 * @see {@link https://webpack.js.org/api/hot-module-replacement/}
 */
if (import.meta.webpackHot) import.meta.webpackHot.accept(console.error);
