    /*********************************
    /* Accordions
    /********************************/
    if (jQuery('#faqSearch').length) {
      jQuery('#faqSearch').on('keyup', function() {
        var value = jQuery(this).val().toLowerCase();
        
        jQuery('.accordion-container').each(function() {
          var container = jQuery(this);
          var allHidden = true; // Flag to track if all accordion sections within the container are hidden
          
          container.find('.accordion').each(function() {
            var triggerText = jQuery(this).find('.accordion-trigger').text().toLowerCase();
            var contentText = jQuery(this).find('.accordion-content').text().toLowerCase();
            
            if (triggerText.includes(value) || contentText.includes(value)) {
              jQuery(this).show(); // Show the current accordion section
              allHidden = false; // At least one section is visible
            } else {
              jQuery(this).hide(); // Hide the current accordion section
            }
          });
          
          // Hide the container if all accordion sections within it are hidden
          if (allHidden) {
            container.hide();
          } else {
            container.show();
          }
        });
      });
  }
