const inViewport = (entries) => {
  entries.forEach(entry => {
    entry.target.classList.toggle('is-inViewport', entry.isIntersecting);
  });
};

const Obs = new IntersectionObserver(inViewport);
const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options

// Attach observer to every [data-inviewport] element:
const ELs_inViewport = document.querySelectorAll('[data-inviewport]');
ELs_inViewport.forEach(EL => {
  Obs.observe(EL, obsOptions);
});

if (jQuery('[data-featuredimg]').length) {
  let getBgImg = jQuery('[data-featuredimg]').attr('data-featuredimg');
  jQuery('.bg-featuredhero').css('background-image', `url(${getBgImg})`);
}

 /*****************
   * Responsive menu
  *****************/
 jQuery('.hamburger').on('click', ()=> { // Mobile menu button
  jQuery('.hamburger').toggleClass('open');
  jQuery('.blank-primary').toggleClass('hidden').toggleClass('open'); // Show blank menu
  jQuery('html,body').stop().toggleClass('no-scroll'); // Block body scrolling
});