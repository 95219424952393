export const countdowntimer = async (err) => {
  if (err) {
      // handle hmr errors
      console.error(err);
  }
  if (document.querySelectorAll('.block-timer').length > 0) {
    let dateTime = parseInt(document.querySelector('.block-timer').getAttribute('data-date')) * 1000; // Convert to milliseconds
    let countDownDate = dateTime;

    setInterval(function() {
        let now = new Date().getTime();
        const pageDoc = document.documentElement;
        const dataRegion = pageDoc.dataset.region;

        if (dataRegion === 'us' || dataRegion === 'ca') {
            let newNow = new Date();
            let datePst = parseInt(document.querySelector('.block-timer').getAttribute('data-pst'));
            newNow = newNow.setHours(newNow.getHours() - datePst);
            now = newNow;
        }

        let timeleft = countDownDate - now;

        let days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
        let hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        let minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        let seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        document.querySelector('.block-timer .days').innerHTML = days;
        document.querySelector('.block-timer .hours').innerHTML = hours;
        document.querySelector('.block-timer .minutes').innerHTML = minutes;
        document.querySelector('.block-timer .seconds').innerHTML = seconds;

        if (timeleft < 0) {
            document.querySelector('.block-timer').style.display = 'none';
        }

    }, 1000);
}
  // ...
};

import.meta.webpackHot?.accept(countdowntimer);